import { Conditions, CollectionInstanceIdentifiers, Answers } from '@breathelife/types';

import { createStack, Stack } from '../utils';

interface EvaluationVisitor<TOut, TCompleteOutput> {
  visitDefault: VisitNodeFunction<any, TOut>;
  visitSectionGroup?: VisitNodeFunction<any, TOut>;
  visitSection?: VisitNodeFunction<any, TOut>;
  visitSubsection?: VisitNodeFunction<any, TOut>;
  visitQuestion?: VisitNodeFunction<any, TOut>;
  visitField?: VisitNodeFunction<any, TOut>;
  visitOption?: VisitNodeFunction<any, TOut>;
  complete: () => TCompleteOutput;
}

type VisitNodeFunction<TNodeType, TOut> = (navigation: VisitNavigation<TOut>, props: Props<TNodeType>) => TOut;

type VisitNavigation<TOut> = {
  visitChildren: () => void;
  childrenResults: () => TOut[];
};

type Props<TNodeType> = {
  node: TNodeType;
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers;
  rule?: Conditions;
  answers: Answers;
};

enum NodeType {
  SectionGroup = 'SectionGroup',
  Section = 'Section',
  Subsection = 'Subsection',
  Question = 'Question',
  Field = 'Field',
  Option = 'Option',
}
type ExtractRuleFunction = (node: any) => Conditions | undefined;

class NodeEvaluationVisit<TOut, TVisitor extends EvaluationVisitor<TOut, any>> {
  private readonly answers: Answers;
  private readonly evalVisitor: TVisitor;
  private readonly extractRule?: ExtractRuleFunction;
  private readonly resultStack: Stack<TOut[]> = createStack<TOut[]>();

  constructor(answers: Answers, evalVisitor: TVisitor, extractRule?: ExtractRuleFunction) {
    this.answers = answers;
    this.evalVisitor = evalVisitor;
    this.extractRule = extractRule;
  }

  public visitFor<TNode>(
    node: TNode,
    visitChildren: () => void,
    options: {
      nodeType?: NodeType;
      repeatedInstanceIdentifiers: CollectionInstanceIdentifiers;
    },
  ): void {
    const { nodeType, repeatedInstanceIdentifiers } = options;

    const visitFunctionName = `visit${nodeType}` as keyof EvaluationVisitor<unknown, unknown>;
    const visit: VisitNodeFunction<TNode, TOut> = this.evalVisitor[visitFunctionName] ?? this.evalVisitor.visitDefault;

    const currentResults: TOut[] = this.resultStack.peek() ?? [];
    const childrenResults: TOut[] = [];
    this.resultStack.push(childrenResults);

    const extractedRule = this.extractRule ? this.extractRule(node) : undefined;

    const visitResult: TOut = visit(
      {
        visitChildren,
        childrenResults: () => childrenResults,
      },
      {
        node,
        repeatedInstanceIdentifiers,
        rule: extractedRule,
        answers: this.answers,
      },
    );
    currentResults.push(visitResult);

    this.resultStack.pop();
  }
}

export { NodeEvaluationVisit, VisitNodeFunction, NodeType, ExtractRuleFunction };
