import _ from 'lodash';

import { evaluateConditions } from '@breathelife/condition-engine';
import {
  EngineEffects,
  InsuranceModule,
  Language,
  PlatformType,
  RenderingType,
  Answers,
  IAnswerResolver,
  QuestionnaireBlueprint,
  QuestionnaireScreenConfig,
  Timezone,
  VersionedAnswers,
  ApplicationMode,
  ApplicationContext,
  CollectionInstanceIdentifiers,
  RepeatedAnswersBySurrogateId,
} from '@breathelife/types';

import { RepeatedIndices } from '../answers';
import { AnswerPath, NodeIdAnswersResolver, TranslationAnswersResolver } from '../answersResolver';
import { computedQuestionnaireAnswers } from '../computedAnswers';
import { defaultQuestionnaireAnswers } from '../defaultAnswers';
import { Localized, TextGetter } from '../locale';
import { VisibilityDependencyMap } from '../nodeEvaluation/visibleIf/dependencyMap';
import { calculateProgress } from '../progress';
import { getAllSubsections } from '../questionnaire';
import { filterNodesByApplicationMode } from '../questionnaire/filterNodesByApplicationMode';
import { filterNodesByPlatformType } from '../questionnaire/filterNodesByPlatformType';
import { filterQuestionnaireSectionsByInsuranceModule } from '../questionnaire/filterQuestionnaireSectionsByInsuranceModule';
import { extendQuestionnaireWithScreen } from '../questionnaireHelpers/questionnaireScreenExtenderVisitor';
import {
  ActiveSectionId,
  DEFAULT_CONFIG,
  RenderingQuestionnaire,
  RenderingQuestionnaireGeneratorConfig,
} from '../renderingTransforms';
import {
  BlockingSubsection,
  isBlockingSubsection,
  LocalizedQuestionnaire,
  QuestionnaireDefinition,
  SectionGroup,
  Subsection,
} from '../structure';
import { updateAnswer } from '../updateAnswer';
import { FieldValidationSchemas, areAllFieldsValidAndComplete, makeFieldValidationSchemas } from '../validations';
import { shareQuestionnaireReferences } from './shareQuestionnaireReferences';
import {
  RenderingQuestionnaireGenerator,
  QuestionnaireNodeItem,
  getQuestionnaireNodes,
} from '../renderingTransforms/RenderingQuestionnaireGenerator';

type QuestionnaireContext = {
  platformTypes?: PlatformType[];
  insuranceModules?: InsuranceModule[];
  applicationModes?: ApplicationMode[];
};

export type QuestionnaireEngineConfig = RenderingQuestionnaireGeneratorConfig & {
  screenConfig?: QuestionnaireScreenConfig;
};

type RenderingOpts = {
  renderingType: RenderingType;
  shouldValidateAllAnswers: boolean;
  loadingFields?: boolean;
  allFieldsCompleted?: boolean;
  activeSectionId?: ActiveSectionId;
};

export class QuestionnaireEngine {
  private readonly questionnaire: Localized<QuestionnaireDefinition>;
  private readonly questionnaireNodes: Map<string, QuestionnaireNodeItem>;
  private readonly answersResolverV1: NodeIdAnswersResolver;
  private readonly answersResolverV2: IAnswerResolver;
  private readonly visibilityDependencyMap: VisibilityDependencyMap;
  private readonly config: QuestionnaireEngineConfig;
  private readonly timezone: Timezone;
  private lastRenderingQuestionnaire: RenderingQuestionnaire | null = null;
  private readonly applicationContext: ApplicationContext;
  private readonly fieldsSchemas: FieldValidationSchemas;

  constructor(args: {
    questionnaire: LocalizedQuestionnaire;
    nodeIdToAnswerPathMap: Map<string, AnswerPath>;
    blueprint: QuestionnaireBlueprint;
    timezone: Timezone;
    config?: QuestionnaireEngineConfig;
    context?: QuestionnaireContext;
    additionalTextProcessing?: TextGetter;
    applicationContext?: ApplicationContext;
  }) {
    const config: QuestionnaireEngineConfig = args.config || DEFAULT_CONFIG;
    const context = args.context || {};
    const additionalTextProcessing = args.additionalTextProcessing || ((text: string) => text);
    const applicationContext: ApplicationContext = args.applicationContext || {};

    this.questionnaire = args.questionnaire;
    this.questionnaireNodes = getQuestionnaireNodes(args.questionnaire);
    this.applicationContext = applicationContext;
    this.answersResolverV1 = new NodeIdAnswersResolver(args.nodeIdToAnswerPathMap);
    this.answersResolverV2 = new TranslationAnswersResolver(args.blueprint);

    const { platformTypes, insuranceModules, applicationModes } = context;
    if (insuranceModules) {
      this.questionnaire = filterQuestionnaireSectionsByInsuranceModule(this.questionnaire, insuranceModules);
    }

    if (platformTypes) {
      this.questionnaire = filterNodesByPlatformType(this.questionnaire, platformTypes);
    }

    if (applicationModes) {
      this.questionnaire = filterNodesByApplicationMode(this.questionnaire, applicationModes);
    }

    if (config.screenConfig) {
      this.questionnaire = extendQuestionnaireWithScreen(this.questionnaire, config.screenConfig);
    }

    this.timezone = args.timezone;
    this.config = config;

    this.visibilityDependencyMap = new VisibilityDependencyMap(this.questionnaire);

    this.fieldsSchemas = makeFieldValidationSchemas(additionalTextProcessing, args.timezone);
  }

  private getAnswersWithDefaultValues(
    answers: Answers = {},
    answerResolver: IAnswerResolver,
    secondaryAnswersResolver?: [IAnswerResolver, Answers],
  ): Answers {
    let updatedAnswers = _.cloneDeep(answers);

    updatedAnswers = defaultQuestionnaireAnswers(
      this.questionnaire,
      answerResolver,
      this.visibilityDependencyMap,
      updatedAnswers,
      this.timezone,
      secondaryAnswersResolver,
    );

    updatedAnswers = computedQuestionnaireAnswers(
      this.questionnaire,
      answerResolver,
      this.visibilityDependencyMap,
      updatedAnswers,
      this.timezone,
    );
    return updatedAnswers;
  }

  /**
   * Returns a new `Answers` object with default values and processed computed values as specified in the questionnaire.
   * If an `Answers` object is passed in with existing answer values, these values will not be overwritten; only missing values will be replaced with the default values. A new `Answers` object is returned.
   *
   * @example
   * const answers = { name: 'Joe' };
   * const answersWithDefaultValues = engine.getAnswersWithDefaultValues(answers); => { name: 'Joe', province: 'QC' }
   */
  public getVersionedAnswersWithDefaultValues(
    answers: VersionedAnswers = new VersionedAnswers({ v1: {}, v2: {} }),
  ): VersionedAnswers {
    const v1WithDefaults = this.getAnswersWithDefaultValues(answers.v1, this.answersResolverV1, [
      this.answersResolverV2,
      answers.v2,
    ]);
    const v2WithDefaults = this.getAnswersWithDefaultValues(answers.v2, this.answersResolverV2);

    return new VersionedAnswers({ v1: v1WithDefaults, v2: v2WithDefaults });
  }

  public getAnswer(answers: VersionedAnswers, nodeId: string, repeatedIndices?: RepeatedIndices): unknown {
    return this.answersResolverV1.getAnswer(answers.v1, nodeId, repeatedIndices);
  }

  public getRepeatedAnswers<T extends string>(
    answers: VersionedAnswers,
    collectionNodeId: string,
    nodeIds: T[],
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    return this.answersResolverV1.getRepeatedAnswers(
      answers.v1,
      collectionNodeId,
      nodeIds,
      collectionInstanceIdentifiers,
    );
  }

  // TODO: (long-term) The questionnaire-engine should not have to expose this function.
  public findSectionGroup(sectionGroupId: string): Localized<SectionGroup> | undefined {
    return this.questionnaire.find((sectionGroup) => sectionGroup.id === sectionGroupId);
  }

  public getRepetitionCount(
    answers: VersionedAnswers,
    nodeId: string,
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  ): number | undefined {
    return this.answersResolverV1.getRepetitionCount(answers.v1, nodeId, collectionInstanceIdentifiers);
  }

  public unsetAnswer(
    answers: VersionedAnswers,
    nodeId: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    answersForPath?: Answers,
  ): [boolean, VersionedAnswers] {
    // Cloning because "unsetAnswer" mutate the answers
    const updatedAnswers = _.cloneDeep(answers);
    const hasUnsettedAnswer = this.answersResolverV1.unsetAnswer(
      updatedAnswers.v1,
      nodeId,
      collectionInstanceIdentifiers,
      answersForPath,
    );

    this.answersResolverV2.unsetAnswer(updatedAnswers.v2, nodeId, updatedAnswers.v2, collectionInstanceIdentifiers);

    return [hasUnsettedAnswer, updatedAnswers];
  }

  public removeUndefinedAnswersFromCollection(
    nodeId: string,
    answers: VersionedAnswers,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
  ): [boolean, VersionedAnswers] {
    // Cloning because "removeUndefinedAnswersFromCollection" mutate the answers
    const updatedAnswers = _.cloneDeep(answers);
    const hasRemovedSomething = this.answersResolverV1.removeUndefinedAnswersFromCollection(
      nodeId,
      updatedAnswers.v1,
      collectionInstanceIdentifiers,
    );

    this.answersResolverV2.removeUndefinedAnswersFromCollection(
      nodeId,
      updatedAnswers.v2,
      collectionInstanceIdentifiers,
    );

    return [hasRemovedSomething, updatedAnswers];
  }

  /**
   * Returns a new `Answers` object, with the `newAnswerValue` set at the path that's associated with the `nodeId`.
   * Note: `nodeId` should be used instead of answerPath (Legacy), the latter taking the form of an explicit full path to the answer's key, e.g. `insuredPeople.2.personalInfo.firstName`
   *
   * @example
   * const answers = { name: 'Joe', province: 'QC' };
   * const updatedAnswers = engine.updateAnswer(answers, 'province', 'ON'); // => { name: 'Joe', province: 'ON' }
   */
  public updateAnswer(
    answers: VersionedAnswers,
    nodeId: string,
    newAnswerValue: unknown,
    effects?: EngineEffects,
    repeatedIndices?: RepeatedIndices,
  ): VersionedAnswers {
    const updatedAnswersV1 = updateAnswer(
      this.questionnaire,
      this.answersResolverV1,
      this.visibilityDependencyMap,
      answers.v1,
      nodeId,
      newAnswerValue,
      this.timezone,
      effects,
      repeatedIndices,
      [this.answersResolverV2, answers.v2],
      this.applicationContext,
    );

    const updatedAnswersV2 = updateAnswer(
      this.questionnaire,
      this.answersResolverV2,
      this.visibilityDependencyMap,
      answers.v2,
      nodeId,
      newAnswerValue,
      this.timezone,
      effects,
      repeatedIndices,
      undefined,
      this.applicationContext,
    );

    // Make sure surrogateID of v1 matches surrogateID of V2
    const surrogateId = this.answersResolverV1.getInstanceId(updatedAnswersV1, nodeId, repeatedIndices || {});
    if (surrogateId.success && surrogateId.value) {
      this.answersResolverV2.setInstanceId(updatedAnswersV2, nodeId, repeatedIndices || {}, surrogateId.value);
    }

    return new VersionedAnswers({
      v1: updatedAnswersV1,
      v2: updatedAnswersV2,
    });
  }

  /**
   * Returns a `RenderingQuestionnaire` object which can be described as a questionnaire that has been evaluated with the given `answers`.
   * This method will decorate each questionnaire's node with additional properties holding the results of the following evaluations:
   * - validity of answers
   * - visibility of sections, subsections, questions, fields
   * - completion of the questionnaire
   *
   * @example
   * const answers = { name: 'Joe', province: 'QC' };
   * const renderingOptions = {
   *    renderingType: 'web',
   *    shouldValidateAllAnswers: true,
   * };
   * const renderingQuestionnaire = engine.generateRenderingQuestionnaire(answers, renderingOptions);
   */
  public generateRenderingQuestionnaire(
    answers: VersionedAnswers,
    language: Language,
    text: TextGetter,
    renderingOptions: RenderingOpts,
  ): RenderingQuestionnaire {
    const { renderingType, shouldValidateAllAnswers, allFieldsCompleted } = renderingOptions;

    const renderingQuestionnaireGenerator = new RenderingQuestionnaireGenerator(
      {
        questionnaire: this.questionnaire,
        questionnaireNodes: this.questionnaireNodes,
        answersResolver: this.answersResolverV1,
        answers: answers.v1,
        applicationContext: this.applicationContext,
        language: Language.en, //The questionnaire is already localized can we remove it?
        text,
        shouldValidateAllAnswers,
        fieldValidationSchemas: this.fieldsSchemas,
        allFieldsCompleted,
        renderingType,
        timezone: this.timezone,
        isLoadingFields: renderingOptions.loadingFields, //Check if it is still useful?
      },
      'Fast',
    );

    const nextRenderingQuestionnaire = renderingQuestionnaireGenerator.get();

    if (this.lastRenderingQuestionnaire) {
      const merged = shareQuestionnaireReferences(this.lastRenderingQuestionnaire, nextRenderingQuestionnaire);
      this.lastRenderingQuestionnaire = merged;

      return merged;
    } else {
      this.lastRenderingQuestionnaire = nextRenderingQuestionnaire;
      return nextRenderingQuestionnaire;
    }
  }

  /**
   * Returns a boolean indicating whether the step associated to the stepId is blocking
   */
  public isStepBlocking(stepId: string, answers: Answers): boolean {
    const allSubsections: Localized<Subsection>[] = getAllSubsections(this.questionnaire);
    const relevantStep: Localized<BlockingSubsection> | null =
      (allSubsections.find((subsection) => subsection.id === stepId) as Localized<BlockingSubsection>) ?? null;

    if (!relevantStep) throw Error(`Step not found for id ${stepId}`);

    if (typeof relevantStep.blockedIf !== 'undefined') {
      const isBlocked = evaluateConditions(
        relevantStep.blockedIf,
        answers,
        this.answersResolverV1,
        {},
        this.timezone,
      ).isValid;
      return isBlocked;
    }

    return false;
  }

  /**
   * Returns true if the questionnaire provided with the passed answers is valid and complete, false otherwise
   * @param answers Answers
   * @returns boolean
   */
  public isQuestionnaireComplete(answers: VersionedAnswers, opts?: { enableBlockedIfConditions: boolean }): boolean {
    const allFieldAnswersAreValidAndComplete = areAllFieldsValidAndComplete(
      this.questionnaire,
      answers.v1,
      this.answersResolverV1,
      {
        ...this.config,
        validateAllAnswers: false,
      },
      this.timezone,
    );

    // TODO: DEV-13035 remove the check on blocking rules now that SSQ is deprecated
    if (!opts?.enableBlockedIfConditions) {
      return allFieldAnswersAreValidAndComplete;
    }

    const allSubsections = getAllSubsections(this.questionnaire);
    const noBlockingAnswers = allSubsections.every((subsection) => {
      if (isBlockingSubsection(subsection)) {
        const isBlocked = evaluateConditions(
          subsection.blockedIf,
          answers,
          this.answersResolverV1,
          {},
          this.timezone,
        ).isValid;
        return !isBlocked;
      }
      return true;
    });

    return allFieldAnswersAreValidAndComplete && noBlockingAnswers;
  }

  /**
   * Returns the percentage of progress for the questionnaire provided with the passed answers
   * @param answers Answers
   * @returns number
   */
  public calculateProgress(
    answers: VersionedAnswers,
    isCompleted?: boolean,
    progressOffset?: number,
    landingStepId?: string,
  ): number {
    return calculateProgress(
      this.questionnaire,
      answers.v1,
      this.answersResolverV1,
      progressOffset,
      this.timezone,
      isCompleted,
      landingStepId,
    );
  }
}
