import { Result } from '@breathelife/result';
import {
  IAnswerResolver,
  CollectionInstanceIdentifier,
  CollectionInstanceIdentifiers,
  RepeatedAnswersBySurrogateId,
  Answers,
  QuestionnaireBlueprint,
  NodeInstance,
  SurrogateId,
} from '@breathelife/types';

import { AnswerPath } from './AnswerPath';
import { NodeIdAnswersResolver } from './NodeIdAnswersResolver';
import { makeAnswerPathTreeFromBlueprint } from './makeAnswerPathTreeFromBlueprint';
import { LinkedBlueprintIds, makeLinkedBlueprintIdMap } from './makeLinkedBlueprintIdMap';
import { makeNodeIdToAnswerPathMap } from './makeNodeIdToAnswerPathMap';

export class BlueprintIdAnswersResolver implements IAnswerResolver {
  private readonly answerResolver: NodeIdAnswersResolver;
  private readonly linkedBlueprintIds: LinkedBlueprintIds;

  private static readonly NODE_REFERENCE_PATH_PREFIX = '';

  private constructor(
    idToAnswerPath: Map<string, AnswerPath>,
    linkedBlueprintIds: LinkedBlueprintIds,
    insuredPeopleHackId: string,
  ) {
    this.answerResolver = new NodeIdAnswersResolver(idToAnswerPath, insuredPeopleHackId);
    this.linkedBlueprintIds = linkedBlueprintIds;
  }
  public setAnswers(
    items: { id: string; value: unknown }[],
    answers: Answers,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers | undefined,
    answersForPath?: Answers | undefined,
  ): void {
    for (const item of items) {
      this.setAnswer(item.value, answers, item.id, collectionInstanceIdentifiers, answersForPath);
    }
  }

  static from(blueprint: QuestionnaireBlueprint): BlueprintIdAnswersResolver {
    const tree = makeAnswerPathTreeFromBlueprint(blueprint);

    const idToAnswerPath = makeNodeIdToAnswerPathMap(tree);
    const linkedBlueprintIds = makeLinkedBlueprintIdMap(blueprint);

    const insuredPeopleBlueprintId = blueprint?.sectionGroupBlueprints?.insuredPeople?.id;
    if (!insuredPeopleBlueprintId) {
      throw new Error(
        "Can't create a BlueprintIdAnswersResolver because it's missing the insured-people section group",
      );
    }

    return new BlueprintIdAnswersResolver(idToAnswerPath, linkedBlueprintIds, insuredPeopleBlueprintId);
  }

  public static buildReferencePathKey(value: string | number): string {
    return `${BlueprintIdAnswersResolver.NODE_REFERENCE_PATH_PREFIX}${value}`;
  }

  public knowsId(id: string): boolean {
    return this.answerResolver.knowsId(id);
  }

  getInstanceId(
    answers: Answers,
    id: string,
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  ): Result<string, SurrogateId | undefined> {
    return this.answerResolver.getInstanceId(answers, id, collectionInstanceIdentifiers);
  }

  setInstanceId(
    answers: Answers,
    id: string,
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
    forceInstanceId?: string | undefined,
    idIsLeaf?: boolean,
  ): Result<string, SurrogateId> {
    return this.answerResolver.setInstanceId(answers, id, collectionInstanceIdentifiers, forceInstanceId, idIsLeaf);
  }

  public getAnswer(
    answers: Answers,
    id: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    answersForPath?: Answers,
    skipLeafIdentifier?: boolean,
  ): any | undefined {
    return this.answerResolver.getAnswer(
      answers,
      id,
      collectionInstanceIdentifiers,
      answersForPath,
      skipLeafIdentifier,
    );
  }

  public getCollection(
    answers: Answers,
    id: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
  ): any[] | undefined {
    return this.answerResolver.getCollection(answers, id, collectionInstanceIdentifiers);
  }

  public isCollection(id: string): boolean {
    return this.answerResolver.isCollection(id);
  }

  public getRepeatedAnswers<T extends string>(
    answers: Answers,
    collectionId: string,
    ids: T[],
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  ): RepeatedAnswersBySurrogateId<T> | undefined {
    return this.answerResolver.getRepeatedAnswers(answers, collectionId, ids, collectionInstanceIdentifiers);
  }

  public getRepetitionCount(
    answers: Answers,
    collectionId: string,
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  ): number | undefined {
    return this.answerResolver.getRepetitionCount(answers, collectionId, collectionInstanceIdentifiers);
  }

  public setAnswer(
    value: unknown,
    answers: Answers,
    id: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    answersForPath?: Answers,
  ): void {
    this.answerResolver.setAnswer(value, answers, id, collectionInstanceIdentifiers, answersForPath);

    const linkedIds = this.linkedBlueprintIds.get(id) || [];

    linkedIds.forEach((linkedId) => {
      this.answerResolver.setAnswer(value, answers, linkedId, collectionInstanceIdentifiers, answersForPath);
    });
  }

  public unsetAnswer(
    answers: Answers,
    id: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
    answersForPath?: Answers,
  ): boolean {
    const linkedIds = this.linkedBlueprintIds.get(id) || [];

    linkedIds.forEach((linkedId) =>
      this.answerResolver.unsetAnswer(answers, linkedId, collectionInstanceIdentifiers, answersForPath),
    );

    return this.answerResolver.unsetAnswer(answers, id, collectionInstanceIdentifiers, answersForPath);
  }

  unsetAnswers(answers: Answers, nodeInstancesToRemove: NodeInstance[]): NodeInstance[] {
    const removedNodeInstances: NodeInstance[] = [];

    for (const nodeInstance of nodeInstancesToRemove) {
      const { id, collectionInstanceIdentifiers } = nodeInstance;
      const wasRemoved = this.unsetAnswer(answers, id, collectionInstanceIdentifiers);

      if (wasRemoved) {
        removedNodeInstances.push(nodeInstance);
      }
    }

    return removedNodeInstances;
  }

  public unsetAnswerSelectOptionId(
    answers: Answers,
    id: string,
    optionId: string,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
  ): boolean {
    const linkedIds = this.linkedBlueprintIds.get(id) || [];

    linkedIds.forEach((linkedId) =>
      this.answerResolver.unsetAnswerSelectOptionId(answers, linkedId, optionId, collectionInstanceIdentifiers),
    );

    return this.answerResolver.unsetAnswerSelectOptionId(answers, id, optionId, collectionInstanceIdentifiers);
  }

  public withCollectionIdentifier(
    identifiers: CollectionInstanceIdentifiers,
    collectionIdentifier: CollectionInstanceIdentifier,
    collectionId: string,
  ): CollectionInstanceIdentifiers {
    return this.answerResolver.withCollectionIdentifier(identifiers, collectionIdentifier, collectionId);
  }

  public removeUndefinedAnswersFromCollection(
    id: string,
    answers: Answers,
    collectionInstanceIdentifiers?: CollectionInstanceIdentifiers,
  ): boolean {
    return this.answerResolver.removeUndefinedAnswersFromCollection(id, answers, collectionInstanceIdentifiers);
  }
}
