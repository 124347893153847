import _ from 'lodash';

import {
  evaluateConditions,
  evaluateQuery,
  generateValidationDataFromValidationType,
} from '@breathelife/condition-engine';
import {
  CollectionInstanceIdentifiers,
  Answers,
  Timezone,
  IAnswerResolver,
  ConditionValidationData,
  FieldTypeValidation,
} from '@breathelife/types';

import { hasBeenAnswered } from '../../answers';
import { EvaluatedValidityNode, TransitionNodeWithVisibility } from '../../renderingTransforms';
import { Field } from '../../structure';
import { EvaluationVisitor, VisitNodeFunction } from '../EvaluationVisitor';
import { EvaluatedMessageParams, isMessageQuery, MessageParams, ValidityRule } from './validityRule';
import { Validations } from '../../validations';

export type ValidityNode = TransitionNodeWithVisibility & EvaluatedValidityNode;

type EvaluationResult = {
  node: ValidityNode;
  hasBeenAnswered: boolean;
  brokenRules: EvaluatedRule[];
  validationData?: ConditionValidationData;
};

type EvaluatedRule = Omit<ValidityRule<unknown>, 'conditions' | 'messageParams'> & {
  isValid: boolean;
  evaluatedMessageParams?: EvaluatedMessageParams;
};

type ValidityExtension = {
  processFieldEvaluation: (props: EvaluationResult) => void;
};

export function evaluateRules(
  rules: ValidityRule<unknown>[],
  answers: Answers,
  answersResolver: IAnswerResolver,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone,
  fieldTypeValidation?: Validations | FieldTypeValidation,
): { validRules: EvaluatedRule[]; invalidRules: EvaluatedRule[]; validationData?: ConditionValidationData } {
  const validRules: EvaluatedRule[] = [];
  const invalidRules: EvaluatedRule[] = [];
  let validationData: ConditionValidationData = {};

  for (const rule of rules) {
    const { isValid } = evaluateConditions(
      rule.conditions,
      answers,
      answersResolver,
      repeatedInstanceIdentifiers,
      timezone,
    );

    const evaluatedMessageParams =
      rule.messageParams &&
      evaluateMessageParams(rule.messageParams, answers, answersResolver, repeatedInstanceIdentifiers, timezone);

    if (isValid) {
      validRules.push({ isValid, message: rule.message, evaluatedMessageParams });
    } else {
      invalidRules.push({ isValid, message: rule.message, evaluatedMessageParams });
    }
  }

  if (fieldTypeValidation) {
    validationData = {
      ...generateValidationDataFromValidationType(fieldTypeValidation as FieldTypeValidation, timezone, validationData),
    };
  }

  return { validRules, invalidRules, validationData };
}

function evaluateMessageParams(
  messageParams: MessageParams,
  answers: Answers,
  answersResolver: IAnswerResolver,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  timezone: Timezone,
): { [key: string]: any } {
  return _.mapValues(messageParams, (value) => {
    if (isMessageQuery(value)) {
      return evaluateQuery(value.fromQuery, answers, answersResolver, repeatedInstanceIdentifiers, timezone);
    }
    return value;
  });
}

function createValidityVisitor(
  { processFieldEvaluation }: ValidityExtension,
  answersResolver: IAnswerResolver,
  timezone: Timezone,
): EvaluationVisitor<void, void> {
  const visitField: VisitNodeFunction<Field, void> = (navigation, { node, answers, repeatedInstanceIdentifiers }) => {
    const nodeWithEvaluatedVisibility = node as Field & TransitionNodeWithVisibility;

    if (!nodeWithEvaluatedVisibility.visible) {
      // Invisible nodes cannot be invalid
      return;
    }

    const { invalidRules, validationData } = evaluateRules(
      node.validIf ?? [],
      answers,
      answersResolver,
      repeatedInstanceIdentifiers,
      timezone,
      node?.validation?.type,
    );

    const fieldAnswer = answersResolver.getAnswer(answers, node.nodeId, repeatedInstanceIdentifiers);
    processFieldEvaluation({
      node: node as Field & ValidityNode,
      hasBeenAnswered: hasBeenAnswered(fieldAnswer),
      brokenRules: invalidRules,
      validationData,
    });
  };

  return {
    visitDefault: (navigation) => navigation.visitChildren(),
    visitField,
    complete: () => {},
  };
}

export { EvaluatedRule, createValidityVisitor };
