import { v4 as uuid } from 'uuid';

import { evaluateConditions } from '@breathelife/condition-engine';
import {
  BooleanOperator,
  CollectionInstanceIdentifiers,
  NodeInstance,
  Answers,
  Timezone,
  IAnswerResolver,
} from '@breathelife/types';

import { ExpandedRepetitionsVisitor } from '../expandedContext/ExpandedRepetitionsVisitor';
import { getConditionalDefaultValueForField } from '../nodeEvaluation/defaultIf/defaultIf';
import { VisibilityDependencyMap } from '../nodeEvaluation/visibleIf/dependencyMap';
import { filterVisibleAnswers } from '../nodeEvaluation/visibleIf/filterVisibleAnswers';
import { getInitialFieldValue } from '../questionnaire';
import {
  Field,
  isRepeatableOptionsBasedOnCollection,
  QuestionnaireDefinition,
  RepeatableQuestion,
  RepeatableSectionGroup,
} from '../structure';
import { Localized } from '../locale';

export class DefaultAnswersVisitor extends ExpandedRepetitionsVisitor {
  private visibilityDependencyMap: VisibilityDependencyMap;
  private changedNodeInstances: NodeInstance[] = [];
  private readonly timezone: Timezone;
  private readonly secondaryAnswersResolver?: [IAnswerResolver, Answers];

  constructor(
    answersResolver: IAnswerResolver,
    answers: Answers,
    visibilityDependencyMap: VisibilityDependencyMap,
    timezone: Timezone,
    secondaryAnswersResolver?: [IAnswerResolver, Answers],
  ) {
    super(answersResolver, answers);
    this.visibilityDependencyMap = visibilityDependencyMap;
    this.timezone = timezone;
    this.secondaryAnswersResolver = secondaryAnswersResolver;
  }

  public getDefaultAnswers(): Answers {
    return this.answers;
  }

  public getChangedNodeInstances(): NodeInstance[] {
    return [...this.changedNodeInstances];
  }

  protected visitRepeatedSectionGroup(sectionGroup: Localized<RepeatableSectionGroup>): void {
    if (!sectionGroup.readOnly) {
      this.setDefaultSurrogateId(sectionGroup.nodeId);
      super.visitRepeatedSectionGroup(sectionGroup);
    }
  }

  protected visitRepeatedQuestion(question: Localized<RepeatableQuestion>, repetitionIndex: number): void {
    if (!question.readOnly) {
      if (isRepeatableOptionsBasedOnCollection(question.options)) {
        this.setDefaultSurrogateId(question.nodeId, question.options.expandToCollectionLength, repetitionIndex);
      } else {
        this.setDefaultSurrogateId(question.nodeId);
      }

      super.visitRepeatedQuestion(question, repetitionIndex);
    }
  }

  protected visitField(field: Localized<Field>): void {
    this.setDefaultFieldValue(field);
  }

  private setDefaultSurrogateId(nodeId: string, collectionNodeId?: string, repetitionIndex?: number): void {
    const repeatedInstanceIdentifiers = this.repeatedInstanceIdentifiers();
    if (typeof repeatedInstanceIdentifiers[nodeId] === 'undefined') {
      // If no index is provided we cannot create a surrogateId for a collection item.
      return;
    }

    let identifiedAnswerItem = this.answersResolver.getAnswer(this.answers, nodeId, repeatedInstanceIdentifiers);

    if (typeof identifiedAnswerItem === 'undefined') {
      identifiedAnswerItem = {};
      this.answersResolver.setAnswer(identifiedAnswerItem, this.answers, nodeId, repeatedInstanceIdentifiers);
    }

    if (!identifiedAnswerItem.surrogateId) {
      let surrogateId: string | undefined = undefined;
      if (repeatedInstanceIdentifiers && collectionNodeId && repetitionIndex !== undefined) {
        //If we have an index and a collection NodeId, use the surrogateId from the same index in the collection.
        const correspondingItemInAnotherCollectionItem = this.answersResolver.getAnswer(
          this.answers,
          collectionNodeId,
          { [collectionNodeId]: repetitionIndex },
        );
        surrogateId = correspondingItemInAnotherCollectionItem?.surrogateId || uuid();
      } else {
        surrogateId = uuid();
      }

      this.answersResolver.setInstanceId(this.answers, nodeId, repeatedInstanceIdentifiers, surrogateId);
      if (this.secondaryAnswersResolver) {
        this.secondaryAnswersResolver[0].setInstanceId(
          this.secondaryAnswersResolver[1],
          nodeId,
          repeatedInstanceIdentifiers,
          surrogateId,
        );
      }
    }
  }

  private setDefaultFieldValue(field: Localized<Field>): void {
    const { defaultValue: defaultFieldValue, nodeId, defaultIf } = field;
    if (typeof defaultFieldValue === 'undefined' && typeof defaultIf === 'undefined') {
      return;
    }

    const collectionInstanceIdentifiers = this.repeatedInstanceIdentifiers();
    if (!this.isFieldVisible(field, collectionInstanceIdentifiers)) {
      // Only set defaults if the field is visible.
      return;
    }

    const defaultValue = field.defaultIf
      ? getConditionalDefaultValueForField(
          field.defaultIf,
          this.answers,
          this.answersResolver,
          collectionInstanceIdentifiers,
          this.timezone,
        )
      : defaultFieldValue;

    const currentAnswer = this.answersResolver.getAnswer(this.answers, nodeId, collectionInstanceIdentifiers);
    const initialFieldValue = getInitialFieldValue(field);

    if (
      currentAnswer === defaultValue ||
      (typeof currentAnswer !== 'undefined' && currentAnswer !== initialFieldValue)
    ) {
      return;
    }

    this.answersResolver.setAnswer(defaultValue, this.answers, nodeId, collectionInstanceIdentifiers);
    this.changedNodeInstances.push({ id: nodeId, collectionInstanceIdentifiers });
  }

  private isFieldVisible(
    field: Localized<Field>,
    collectionInstanceIdentifiers: CollectionInstanceIdentifiers,
  ): boolean {
    const fieldVisibilityConditions = this.visibilityDependencyMap.getVisibilityConditions(field.nodeId);
    const visibilityConditions = fieldVisibilityConditions?.field;
    const isFieldVisible =
      !visibilityConditions ||
      evaluateConditions(
        { conditions: visibilityConditions, operator: BooleanOperator.or },
        this.answers,
        this.answersResolver,
        collectionInstanceIdentifiers,
        this.timezone,
      ).isValid;
    return isFieldVisible;
  }
}

export function defaultQuestionnaireAnswers(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: IAnswerResolver,
  dependencyMap: VisibilityDependencyMap,
  existingAnswers: Answers,
  timezone: Timezone,
  secondaryAnswersResolver?: [IAnswerResolver, Answers],
): Answers {
  const defaultAnswersVisitor = new DefaultAnswersVisitor(
    answersResolver,
    existingAnswers,
    dependencyMap,
    timezone,
    secondaryAnswersResolver,
  );
  defaultAnswersVisitor.visitQuestionnaire(questionnaire);

  let updatedAnswers = defaultAnswersVisitor.getDefaultAnswers();

  // Setting defaults may affect the visibility of other answers.
  for (const nodeInstance of defaultAnswersVisitor.getChangedNodeInstances()) {
    updatedAnswers = filterVisibleAnswers(
      nodeInstance.id,
      dependencyMap,
      answersResolver,
      updatedAnswers,
      nodeInstance.collectionInstanceIdentifiers,
      timezone,
    );
  }

  return updatedAnswers;
}
