import _ from 'lodash';

import { Answers, IAnswerResolver, FieldTypes } from '@breathelife/types';

import { ExpandedContextVisitor, RepetitionIntervalBoundary } from '../expandedContext/ExpandedContextVisitor';
import { parseNumericValue } from '../modelHelper';
import { Field, QuestionnaireDefinition, RepeatableQuestionnaireNode } from '../structure';
import { Localized } from '../locale';

class FormatAnswersVisitor extends ExpandedContextVisitor {
  private static NUMERIC_FIELD_TYPES = [FieldTypes.number, FieldTypes.money];

  private answers: Answers;
  private answersResolver: IAnswerResolver;

  constructor(answers: Answers, answersResolver: IAnswerResolver) {
    super(RepetitionIntervalBoundary.maxRepetitions);
    this.answers = _.cloneDeep(answers);
    this.answersResolver = answersResolver;
  }

  public getFormattedAnswers(): Answers {
    return _.cloneDeep(this.answers);
  }

  protected numberOfRepetitions(repeatableNode: RepeatableQuestionnaireNode): number {
    return (
      this.answersResolver.getRepetitionCount(
        this.answers,
        repeatableNode.nodeId,
        this.repeatedInstanceIdentifiers(),
      ) ?? 0
    );
  }

  protected visitField(field: Localized<Field>): void {
    if (FormatAnswersVisitor.NUMERIC_FIELD_TYPES.includes(field.type)) {
      const repeatedInstanceIdentifiers = this.repeatedInstanceIdentifiers();
      const answer = this.answersResolver.getAnswer(this.answers, field.nodeId, repeatedInstanceIdentifiers);

      if (typeof answer !== 'string') {
        return;
      }

      const numberAnswer = parseNumericValue(answer);
      if (isNaN(numberAnswer)) {
        // Unset NaN answers so we never save NaN when a numeric field answer is an empty or invalid string
        // Also required as part of workaround of issue with Yup rejecting optional number inputs when left empty
        const nodeInstancesToRemove = [
          { id: field.nodeId, collectionInstanceIdentifiers: repeatedInstanceIdentifiers },
        ];
        this.answersResolver.unsetAnswers(this.answers, nodeInstancesToRemove);
      } else {
        // Replace the string answer by a number
        this.answersResolver.setAnswer(numberAnswer, this.answers, field.nodeId, repeatedInstanceIdentifiers);
      }
    }
  }
}

export function formatAnswers(
  questionnaire: Localized<QuestionnaireDefinition>,
  answersResolver: IAnswerResolver,
  answers: Answers,
): Answers {
  const visitor = new FormatAnswersVisitor(answers, answersResolver);
  visitor.visitQuestionnaire(questionnaire);
  return visitor.getFormattedAnswers();
}
