import _ from 'lodash';

import { isConditions } from '@breathelife/condition-engine';
import {
  Condition,
  Conditions,
  Localizable,
  CollectionInstanceIdentifiers,
  Answers,
  IAnswerResolver,
} from '@breathelife/types';

import { EvaluationVisitor, VisitNodeFunction } from '../nodeEvaluation/EvaluationVisitor';
import { DynamicOptions, isDynamicOptionField, SelectOption } from '../structure';
import { TransitionField, TransitionNode, TransitionNodeWithMetadata } from './Questionnaire';
import { RenderingFieldOption } from './RenderingQuestionnaire';
import { TransformEvaluationVisitorAdapter } from './TransformVisitor';

export const REPLACE_WITH_OPTION_ID = 'REPLACE_WITH_OPTION_ID';

type QuestionnaireWithOptionType<TOptionType> = (TransitionNodeWithMetadata & {
  sections: (TransitionNode & {
    subsections: (TransitionNode & {
      questions: (TransitionNodeWithMetadata & {
        fields: (TransitionNodeWithMetadata & { options?: TOptionType[] })[];
      })[];
    })[];
  })[];
})[];

type PopulatedDynamicOption = TransitionNode & SelectOption & Pick<RenderingFieldOption, 'text'>;

type UnpopulatedDynamicOptionsQuestionnaire = QuestionnaireWithOptionType<SelectOption>;
export type PopulatedDynamicOptionsQuestionnaire = QuestionnaireWithOptionType<PopulatedDynamicOption>;

export function populateDynamicOptions(
  questionnaire: UnpopulatedDynamicOptionsQuestionnaire,
  answers: Answers,
  answersResolver: IAnswerResolver,
): PopulatedDynamicOptionsQuestionnaire {
  const populateDynamicOptionsVisitor = createPopulateDynamicOptionsVisitor(answersResolver);

  const visitorAdapter = new TransformEvaluationVisitorAdapter(answers, populateDynamicOptionsVisitor);
  visitorAdapter.visitQuestionnaire(questionnaire);

  return questionnaire;
}

export function getDynamicOptions(
  dynamicOptions: DynamicOptions,
  answers: Answers,
  repeatedInstanceIdentifiers: CollectionInstanceIdentifiers,
  answersResolver: IAnswerResolver,
): SelectOption[] {
  const collectionNodeId: string = dynamicOptions.collection;
  const selectNodeIds: string[] = dynamicOptions.select;
  const optionVisibleIf = dynamicOptions.visibleIf;

  const answersBySurrogateId = answersResolver.getRepeatedAnswers(
    answers,
    collectionNodeId,
    selectNodeIds,
    repeatedInstanceIdentifiers ?? [],
  );

  let options: SelectOption[] = [];
  if (typeof answersBySurrogateId !== 'undefined') {
    options = Object.entries(answersBySurrogateId)
      .map(([surrogateId, { answersByNodeId, repeatedIndex }]) => {
        const repeatedInstanceIdentifierContext = dynamicOptions.useLocalIdentifiers
          ? { ...repeatedInstanceIdentifiers }
          : answersResolver.withCollectionIdentifier(repeatedInstanceIdentifiers, repeatedIndex, collectionNodeId);

        return {
          id: surrogateId,
          text: formatOptionText(selectNodeIds, answersByNodeId),
          metadata: { repeatedInstanceIdentifierContext },
          visibleIf: optionVisibleIf && replaceWithCurrentOptionValue(surrogateId, _.cloneDeep(optionVisibleIf)),
        };
      })
      .filter((option) => option.text.en || option.text.fr);
  }

  return options;
}

/** If a condition `value` property is `REPLACE_WITH_OPTION_ID` replace it with `currentOptionId` */
function replaceWithCurrentOptionValue(currentOptionId: string, conditions?: Conditions): Conditions | undefined {
  if (!conditions || !conditions.conditions.length) return conditions;

  conditions.conditions = conditions.conditions.map((condition: Condition) => {
    if (isConditions(condition)) {
      // Recurse if this condition contains other conditions.
      const subConditions = replaceWithCurrentOptionValue(currentOptionId, condition);
      if (!subConditions) {
        throw Error('subConditions cannot be undefined');
      }

      return subConditions;
    }

    if (condition.value === REPLACE_WITH_OPTION_ID) {
      condition.value = currentOptionId;
    }

    return condition;
  });

  return conditions;
}

function createPopulateDynamicOptionsVisitor(answersResolver: IAnswerResolver): EvaluationVisitor<void, void> {
  const visitField: VisitNodeFunction<TransitionField, void> = (
    navigation,
    { node, answers, repeatedInstanceIdentifiers },
  ) => {
    if (isDynamicOptionField(node)) {
      const dynamicOptions = getDynamicOptions(
        node.dynamicOptions,
        answers,
        repeatedInstanceIdentifiers ?? {},
        answersResolver,
      );

      const transitionDynamicOptions: PopulatedDynamicOption[] = dynamicOptions.map(
        (option) =>
          ({
            ...option,
            // TODO: When fr/en can possibly differ, localize this.
            text: option.text.en,
          }) as PopulatedDynamicOption,
      );

      node.options = [...transitionDynamicOptions, ...(node.options || [])];
    }
  };

  return {
    visitDefault: (navigation) => navigation.visitChildren(),
    visitField,
    complete: () => {},
  };
}

// formatOptionText currently concatenates all non-empty answers together.
// This may be replaced by a more advanced formatting system.
function formatOptionText(nodeIds: string[], answersByNodeId: { [nodeId: string]: any }): Localizable {
  const definedValues = nodeIds
    .map((nodeId) => answersByNodeId[nodeId])
    .filter((value) => typeof value !== 'undefined' && value !== '');

  const text = definedValues.join(' ');

  // In a more advanced formatting system these values may differ (right now they are in this form so the option's type is correct).
  return { en: text, fr: text };
}
